/*
** Utils - Helpers
** ----------------------------------------------------------------------------- */

.u-sr-only {
  position: absolute;
  width: 0.1em;
  height: 0.1em;
  padding: 0;
  margin: -0.1em;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;

  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}
