/*
** Module - Nav primary
** ----------------------------------------------------------------------------- */

.nav-primary {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: -1.5em;
  margin-top: auto;
  margin-bottom: auto;

  @include breakpoint(lg) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -2.5em;
  }
}

.nav-primary__item {
  margin-top: 0.66em;
  margin-left: 1.5em;

  @include breakpoint(lg) {
    margin-top: 0;
    margin-left: 2.5em;
  }
}

.nav-primary__link {
  @extend %style-oswald;
  display: block;
  padding-bottom: calc(0.5rem - 1px);
  border-bottom: 3px solid transparent;
  font-size: 7.5vw;

  @include breakpoint(md) {
    font-size: 5vw;
  }

  @include breakpoint(lg) {
    display: inline;
    padding-bottom: calc(1.5rem - 1px);
    font-size: 1em;
  }

  .is-active &,
  .current-menu-item &,
  .current-page-ancestor & {
    font-weight: bold;
    border-color: $color-primary;
  }

  &[href]:hover {
    color: $color-primary;
  }
}

