/*
** Module - Popup
** ----------------------------------------------------------------------------- */

.popup {
  @include u-overlay(0, 'fixed');
  z-index: $layer-popup;
  overflow: hidden;
  pointer-events: none;
  transform: translateX(-300%);
  transition: transform 0s $transition-duration;

  .has-popup-open & {
    transform: translateX(0);
    pointer-events: all;
    transition: none;
  }
}

.popup__close {
  position: absolute;
  top: 0.75em;
  right: 0.5em;
  z-index: $layer-popup;
  padding: 0.5em;
  border-radius: 50%;
  color: $color-black;
  font-size: 1.25em;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  .is-auto & {
    color: $color-white;
  }
}

.popup__content {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  width: calc(100% - 4rem);
  max-width: 45rem;
  max-height: calc(100% - 4rem);
  background-color: $color-white;
  border-radius: 0.5rem;
  box-shadow: 1rem 1rem 2rem rgba($color-black, 0.25);
  overflow: hidden;
  opacity: 0;
  margin-top: 5%;
  transform: translate3d(-50%, -50%, 0);
  transition: opacity $transition-duration * 1.5, margin $transition-duration * 1.5;

  @include breakpoint(md) {
    max-width: 65rem;
  }

  @include breakpoint(lg) {
    max-width: 80rem;
  }

  .has-popup-open & {
    opacity: 1;
    margin-top: 0;
  }

  &::before,
  &::after {
    content: '';
    @include u-overlay;
    z-index: $layer-base;
    height: 2em;
    user-select: none;
    pointer-events: none;

    @include breakpoint(md) {
      height: 2.5em;
    }
  }

  &::before {
    bottom: auto;
    background-image: linear-gradient(to bottom, rgba($color-white, 0.75) 0%, rgba($color-white, 0) 100%);
  }

  &::after {
    top: auto;
    background-image: linear-gradient(to top, rgba($color-white, 0.75) 0%, rgba($color-white, 0) 100%);
  }

  &.is-auto {
    &::before, &::after {
      content: initial;
    }
  }
}

.popup__inner {
  width: 100%;
  padding: 3rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @include breakpoint(md) {
    padding: 5rem;
  }

  > img,
  > video,
  > iframe {
    display: block;
    width: 100%;
  }

  .is-auto & {
    padding: 0;
  }
}
