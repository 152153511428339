/*
** Module - Page footer
** ----------------------------------------------------------------------------- */

.page-foot {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.page-foot__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include breakpoint(lg) {
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;
  }
}

.page-foot__copy {
  @extend %style-oswald;
}

.page-foot__nav {
  margin-top: 1em;

  @include breakpoint(lg) {
    margin-top: 0;
    margin-left: 2em;
  }
}
