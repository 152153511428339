/*
** Module - Body bag
** ----------------------------------------------------------------------------- */

.body-bag {
  @extend %wrapper;
  position: relative;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &::after {
    @include u-overlay(0, 'fixed');
    content: '';
    z-index: $layer-popup - 1;
    background-color: rgba($color-black, 0.75);
    opacity: 0;
    pointer-events: none;
    transform: translateX(-300%);
    transition: opacity $transition-duration, transform 0s $transition-duration;
  }

  .is-loading &,
  .has-popup-open & {
    &::after {
      opacity: 1;
      transform: translateX(0);
      pointer-events: all;
      transition: opacity $transition-duration 0s, transform 0s 0s;
    }
  }

  .is-loading & {
    &::before {
      @include u-overlay(0, 'fixed');
      content: '';
      z-index: $layer-popup;
      background-image: image('loader.svg');
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
