/*
** Module - Office card
** ----------------------------------------------------------------------------- */

.office-card {
  max-width: 22rem;
}

.office-card__content {
  padding: 1em;
  font-size: 1.4rem;
  line-height: $font-line-height;
}

.office-card__address {
  font-weight: 500;
}

.office-card__details {
  font-size: 1.2rem;

  strong {
    font-weight: bold;
  }

  li:not(:first-child) {
    margin-top: 0.5rem;
  }

  &:not(:first-child) {
    margin-top: 1em;
  }
}
