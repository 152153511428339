/*
** Placeholders - Typography
** ----------------------------------------------------------------------------- */

%style-oswald {
  font-size: 1.8rem;
  font-family: $font-oswald;
  font-weight: normal;
  text-transform: uppercase;
}
