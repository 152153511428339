/*
** Utils - Trademark
** ----------------------------------------------------------------------------- */

.u-trademark {
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;

  em {
    color: $color-primary;
    font-style: normal;
  }
}
