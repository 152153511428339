/*
** Placeholders - Common
** ----------------------------------------------------------------------------- */

// Text ellipsis
%ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Link
%link {
  color: $color-primary;

  &:hover {
    color: currentColor;
  }
}

// Link transitions
%link-transition {
  cursor: pointer;
  transition-duration: $transition-duration;
  transition-property: color, background-color, border, outline, box-shadow, opacity, transform;
}

// Input initial styles
%input {
  display: block;
  width: 100%;
  margin: 0;
  border: 0;
  border-radius: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: normal;
  appearance: none;

  &::placeholder {
    color: currentColor;
    opacity: 0.75;
  }

  &:focus {
    outline: none;
  }

  &[rows] {
    resize: none;
  }

  &[type] {
    @extend %ellipsis;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    appearance: none;
  }
}

%wrapper {
  width: 100%;
  max-width: 192rem;
  margin-left: auto;
  margin-right: auto;
}
