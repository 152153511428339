/*
** Module - Button
** ----------------------------------------------------------------------------- */

.button {
  @extend %button;
  @extend %style-oswald;
  min-width: 14rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.33em 0;
  border-bottom: 2px solid;
  font-weight: 600;
  white-space: nowrap;

  .u-icon {
    margin-left: 4rem;
  }

  &[href]:hover,
  &[type]:hover {
    opacity: 0.8;
  }

  &[type][value] {
    &:focus {
      outline: none;
    }
  }
}
