/*
** Common - Fonts
** ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'Basis Grotesque Pro';
  src: font('basisgrotesquepro-regular-webfont.woff2') format('woff2'),
    font('basisgrotesquepro-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Basis Grotesque Pro';
  src: font('basisgrotesquepro-bold-webfont.woff2') format('woff2'),
    font('basisgrotesquepro-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Basis Grotesque Pro';
  src: font('basisgrotesquepro-light-webfont.woff2') format('woff2'),
    font('basisgrotesquepro-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
