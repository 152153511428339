/*
** Vendor - Flickity
** -----------------------------------------------------------------------------*/

/* prevent page scrolling when flickity is fullscreen */
html.is-flickity-fullscreen {
  overflow: hidden;
}

.flickity-enabled {
  position: relative;

  &:focus {
    outline: none;
  }

  &.is-draggable {
    user-select: none;

    .flickity-viewport {
      cursor: grab;

      &.is-pointer-down {
        cursor: grabbing;
      }
    }
  }
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
  transition: height 0.25s;

  .is-fullscreen & {
    transition: none;
  }
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

.flickity-button {
  [data-animate] & {
    opacity: 0;
  }

  [data-animate].animate-run & {
    animation-name: fade;
    animation-duration: $transition-duration * 3;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: $transition-duration * 2;
  }
}

.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  z-index: $layer-utils;
  opacity: 0.5;
  transform: translateY(-50%);

  &.previous {
    left: 1em;
  }

  &.next {
    right: 1em;
  }

  svg {
    position: relative;
    z-index: $layer-base;
    display: block;
    height: 1.5em;
    width: 1.5em;
    fill: currentColor;
    stroke: none;
    transition: opacity $transition-duration;

    @include breakpoint(lg) {
      height: 2em;
      width: 2em;
    }
  }

  &[disabled] {
    cursor: default;

    svg,
    &::before,
    &::after {
      opacity: 0.25;
    }
  }

  &:not([disabled]):hover {
    opacity: 0.8;
  }
}

