/*
** Common - Base
** ----------------------------------------------------------------------------- */


body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  color: $font-color;
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $font-line-height;

  // These values will not show up in content, but can be
  // queried by JavaScript to know which breakpoint is active.
  // Add or remove as many breakpoints as you like.
  &::before {
    display: none;

    @each $breakpoint, $size in $grid-breakpoints {
      @include breakpoint($breakpoint) {
        content: '#{$breakpoint}';
      }
    }
  }
}

a,
button {
  @extend %link-transition;
}
