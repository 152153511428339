/*
** Placeholders - Button
** ----------------------------------------------------------------------------- */

%button {
  @extend %link-transition;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}
