/*
** Module - Map
** ----------------------------------------------------------------------------- */

.map {
  width: 100%;
  height: 75vh;
  max-height: 50rem;
  background-color: $color-shade;

  .mapboxgl-ctrl-bottom-left,
  .mapboxgl-ctrl-bottom-right {
    display: none;
  }

  .mapboxgl-popup {
    z-index: $layer-utils;
  }

  .mapboxgl-popup-content {
    padding: 0;
    box-shadow: 0 0 1rem rgba($color-black, 0.3);
    font-family: $font-roboto;
  }

  .mapboxgl-popup-close-button {
    right: 0.5em;
    top: 0.5em;
    display: block;
    height: 1.25em;
    width: 1.25em;
    background-color: $color-white;
    border-radius: 50%;
    font-size: 1.6rem;
    line-height: 1;
    transition: background-color $transition-duration;

    &:hover {
      background-color: $color-shade;
    }
  }
}

.map__marker {
  font-size: 4.8rem;
  margin-top: -2.4rem;
  cursor: pointer;
  transition: color $transition-duration;

  &:hover {
    color: $color-primary;
  }

  &[data-title]::after {
    @extend %style-oswald;
    content: attr(data-title);
    position: absolute;
    top: 100%;
    left: 50%;
    padding-top: 0.5rem;
    transform: translateX(-50%);
  }
}
