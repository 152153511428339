/*
 ** Module - Grid
 ** ----------------------------------------------------------------------------- */

.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0.66em;

  @include breakpoint(md) {
    grid-template-columns: repeat(2, 1fr);
  }
}
