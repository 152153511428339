/*
** Module - Tile
** ----------------------------------------------------------------------------- */

.tile__grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1px;

  @include breakpoint(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint(lg) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.tile {
  position: relative;

  &--tall {
    @include breakpoint(md) {
      grid-row: span 2;
    }
  }
}

.tile__image {
  @include u-object-fit(75%);
  height: 100%;

  @include breakpoint(md) {
    @include u-object-fit(100%);
  }

  &--dimmed {
    &::after {
      @include u-overlay;
      content: '';
      background-color: rgba($color-black, 0.2);
    }
  }
}

.tile__overlay {
  @include u-overlay;
  z-index: $layer-base;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  color: $color-white;
  text-align: center;
  transition: background $transition-duration;

  [href]:hover & {
    background-color: rgba($color-primary, 0.7);
  }
}

.tile__title {
  font-weight: 500;
  white-space: nowrap;
  backface-visibility: hidden;
  transition: transform $transition-duration;

  [href]:hover & {
    transform: translateY(-6rem);
  }
}

.tile__details {
  position: absolute;
  top: 50%;
  font-size: 1.6rem;
  max-width: 20em;
  margin-top: 3rem;
  opacity: 0;
  transform: translateY(0%);
  backface-visibility: hidden;
  transition-property: transform, opacity;
  transition-duration: $transition-duration;

  [href]:hover & {
    opacity: 1;
    transform: translateY(-6rem);
  }
}

.tile__button {
  &:not(:first-child) {
    margin-top: 1.75em;
  }
}
