/*
** Utils - Logo
** ----------------------------------------------------------------------------- */

.u-logo {
  display: block;
  height: 6rem;

  @include breakpoint(md) {
    height: 8.4rem;
  }

  img {
    height: 100%;
  }

  &[href]:hover {
    opacity: 0.8;
  }

  &--sm {
    height: 3.2rem;
  }

  &--md {
    height: 5rem;
  }

  &--lg {
    height: 6rem;

    @include breakpoint(md) {
      height: 8.4rem;
    }
  }
}
