/*
** Module - Nav language
** ----------------------------------------------------------------------------- */

.nav-language {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -1.75em;
}

.nav-language__item {
  margin-left: 1.75em;
}

.nav-language__link {
  @extend %style-oswald;
  display: block;
  padding: 0.25em 0.5em;
  margin: -0.25em -0.5em;
  border: 1px solid transparent;

  .is-active &,
  .wpml-ls-current-language & {
    border-color: $color-primary;
  }

  &[href]:hover {
    color: $color-primary;
  }
}

