/*
** Module - Page header
** ----------------------------------------------------------------------------- */

.page-head {
  position: absolute;
  z-index: $layer-head;
  top: 0;
  left: 0;
  right: 0;

  .has-nav-open & {
    position: fixed;
  }

  + *:not(.hero) {
    margin-top: calc(6rem + 3rem);

    @include breakpoint(md) {
      margin-top: calc(8.4rem + 3rem);
    }
  }
}

.page-head__inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $layer-overlay + 1;
    border-bottom: 1px solid rgba($color-black, 0.15);
  }

  @include breakpoint(lg) {
    align-items: stretch;
  }
}

.page-head__logo {
  align-self: center;
}

.page-head__navs {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.page-head__top {
  display: flex;
}

.page-head__bottom {
  position: absolute;

  @include breakpoint(lg) {
    position: relative;

    &:not(:first-child) {
      margin-top: 1em;
    }
  }
}

.page-head__label {
  width: 1.33em;
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;

  &:not(:first-child) {
    margin-left: 1.5em;

    @include breakpoint(lg) {
      margin-left: 2.5em;
    }
  }
}

.page-head__hamburger {
  display: flex;
  align-items: center;
  margin-left: 1.5em;
}

.page-head__logo,
.page-head__top,
.page-head__hamburger {
  position: relative;
  z-index: $layer-overlay + 1;
}

.page-head__nav {
  @include u-overlay(0, 'fixed');
  z-index: $layer-overlay;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: calc(6rem + 3rem) $grid-container-gutter 3rem;
  background-color: $color-white;
  color: $color-black;
  opacity: 0;
  transform: translateY(-2.5%);
  pointer-events: none;
  backface-visibility: hidden;
  transition: opacity, transform;
  transition-duration: $transition-duration;

  .has-nav-open & {
    opacity: 1;
    transform: none;
    pointer-events: all;
  }

  @include breakpoint(md) {
    padding-top: calc(8.4rem + 3rem);
  }

  @include breakpoint(lg) {
    position: static;
    display: block;
    padding: 0;
    background-color: transparent;
    color: currentColor;
    opacity: 1;
    transform: none;
    pointer-events: all;

    &:not(:first-child) {
      margin-left: 2em;
    }
  }
}
