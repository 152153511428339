/*
** Utils - Label
** ----------------------------------------------------------------------------- */

.u-label {
  font-size: 1.6rem;

  @include u-float-label(75%) {
    padding-left: 2rem;
    top: 0.25em;
    user-select: none;
    pointer-events: none;

    @include breakpoint(md) {
      top: 0.5em;
      padding-left: 3rem;
    }
  }

  input,
  textarea {
    @include u-float-label-input;
    @include u-float-label-scaled {
      top: 1em;
      opacity: 0.75;

      @include breakpoint(md) {
        top: 1.25em;
      }
    }

    &:focus,
    &:not(:placeholder-shown) {
      padding-top: 1em;
      padding-bottom: 0.5em;

      @include breakpoint(md) {
        padding-top: 1.33em;
        padding-bottom: 0.67em;
      }
    }
  }
}
