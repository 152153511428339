/*
** Module - Section
** ----------------------------------------------------------------------------- */

.section {
  @extend %wrapper;
  position: relative;

  &--padding-md {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  &--padding-lg {
    padding-top: 5rem;
    padding-bottom: 5rem;

    @include breakpoint(lg) {
      padding-top: 8rem;
      padding-bottom: 8rem;
    }
  }

  &--bg {
    background-color: rgba($color-navy, 0.8);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &--dimmed {
    position: relative;
    color: $color-shade;

    &::after {
      @include u-overlay;
      content: '';
      background-color: rgba($color-navy, 0.9);
      pointer-events: none;
    }

    > * {
      position: relative;
      z-index: $layer-base;
    }
  }
}

.section__overlay {
  @include u-overlay;
  top: 5rem;
  z-index: $layer-base;
  pointer-events: none;
  user-select: none;
}

.section__title {
  margin-bottom: 5rem;

  &--centered {
    text-align: center;
  }
}

.section__copy {
  line-height: 1.3;

  @include breakpoint(lg) {
    max-width: 28em;
    margin-bottom: 5.5vw;
  }

  &::before {
    @include u-overlay;
    content: '';
    z-index: $layer-negative;
    background-color: $color-blue;

    @include breakpoint(lg) {
      right: calc(50% - 41rem);
      bottom: 5.5vw;
    }
  }
}

.section__grid {
  @include breakpoint(lg) {
    margin-bottom: -7vw;
  }
}
