/*
** Utils - Underline
** ----------------------------------------------------------------------------- */

.u-underline {
  position: relative;
  display: inline-block;
  padding-bottom: 0.5rem;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: 3px;
    background-color: $color-primary;

    @include breakpoint(md) {
      left: -5rem;
    }
  }

  &--indented {
    @include breakpoint(md) {
      margin-left: 5rem;
    }
  }
}
