/*
** Module - Panel
** ----------------------------------------------------------------------------- */

.panel {
  display: flex;
  flex-direction: column;

  @include breakpoint(md) {
    flex-direction: row;
  }
}

.panel__details,
.panel__content {
  position: relative;

  &::before {
    @include u-overlay;
    content: '';
  }

  > * {
    position: relative;
    z-index: $layer-base;
  }
}

.panel__details {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: $color-white;
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: center;

  @include breakpoint(md) {
    align-items: flex-end;
    width: 38%;
    padding: 5rem;
    padding-left: 0;
    text-align: right;
  }

  &::before {
    left: -25vw;
    right: -25vw;
    background-color: rgba($color-navy, 0.9);

    @include breakpoint(md) {
      right: 0;
    }
  }
}

.panel__content {
  padding-top: 4rem;
  padding-bottom: 4rem;

  @include breakpoint(md) {
    align-self: flex-start;
    width: 62%;
    padding: 5rem 0 10rem 5rem;
  }

  &::before {
    left: -25vw;
    right: -25vw;
    background: linear-gradient(180deg, $color-white 75%, rgba($color-white, 0) 100%);

    @include breakpoint(md) {
      left: 0;
    }
  }
}
