/*
 ** Module - Form
 ** ----------------------------------------------------------------------------- */

.form {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @include breakpoint(lg) {
    max-width: 32.5em;
  }

  &:not(:first-child) {
    margin-top: 1.5em;

    @include breakpoint(lg) {
      margin-top: 2.5em;
    }
  }
}

.form__title {
  padding-right: 4rem;

  &:not(:last-child) {
    margin-bottom: 4rem;
  }
}

.form__content {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: $grid-column-gutter;
  transition: opacity $transition-duration;

  @include breakpoint(md) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $grid-column-gutter;
  }

  .is-loading & {
    opacity: 0.25;
  }
}

.form__fieldset {
  display: contents;

  &[disabled] {
    display: none;
  }
}

.form__field {
  position: relative;
  grid-column: span 1;

  @include breakpoint(md) {
    grid-column: span 2;

    &--half {
      grid-column: span 1;
    }
  }

  &--action {
    display: flex;
    justify-content: flex-end;
  }
}

.form__label {
  display: block;
  cursor: pointer;

  @include breakpoint(md) {
    font-size: 2rem;
  }
}

.form__input {
  @extend %input;
  padding: 0.75em 2rem;
  border: 1px solid currentColor;
  border-radius: 3px;
  font-size: 1.6rem;
  line-height: 1.75;

  @include breakpoint(md) {
    padding: 1em 3rem;
  }
}

.form__file {
  font-size: 1.6rem;
  line-height: 1;
}

.form__loader {
  .is-loading + &::before {
    @include u-overlay;
    content: '';
    z-index: $layer-utils;
    background-color: rgba($color-white, 0.75);
    background-image: image('loader-dark.svg');
    background-size: auto 50%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.form__button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5em;
  width: 1.5em;
  background-color: $color-white;
  box-shadow: 0 0.3rem 0.6rem rgba($color-black, 0.25);
  border-radius: 50%;
}

.form__copy {
  margin-top: 1.5em;
  font-size: 0.888em;
}

.form__message {
  display: none;
  max-width: 25em;

  &[data-status='error'] {
    margin-bottom: $spacer;
    color: $color-status-error;
  }

  &[data-status='success'] {
    color: $color-status-success;
  }

  &.is-visible {
    display: block;
  }
}

.parsley-errors-list[class]:not(:first-child) {
  list-style: none;
  padding: 0;
  margin: 0;
  color: $color-status-error;
  font-size: 1.4rem;

  &.filled {
    margin-top: 0;
    padding-top: 0.5em;
  }
}
