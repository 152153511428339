/*
 ** Module - Copy
 ** ----------------------------------------------------------------------------- */

.copy {
  @for $i from 1 through 6 {
    h#{$i}:not([class]) {
      @extend %heading !optional;
      @extend %heading-h#{$i} !optional;
      font-weight: 500;
      text-transform: uppercase;

      &:not(:first-child) {
        margin-top: 1.5em;
      }
    }
  }

  h1, h2 {
    &:not([class]):not(:first-child) {
      margin-top: 1em;
    }
  }

  p,
  ul,
  ol,
  table,
  address {
    &:not(:first-child) {
      margin-top: 1.5em;
    }
  }

  a {
    @extend %link;
    color: $color-primary;

    &:hover {
      color: currentColor;
    }
  }

  ul, ol {
    padding-left: 1.2em;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    &:not(:first-child) {
      margin-top: 0.5em;
    }
  }

  hr {
    display: block;
    width: 100%;

    &:not(:first-child) {
      margin-top: 1.5em;
    }
  }

  blockquote,
  .alignnone,
  .aligncenter,
  .wp-caption {
    &:not(:first-child) {
      margin-top: 1.5em;
    }

    &:not(:last-child) {
      margin-bottom: 1.5em;
    }
  }

  blockquote {
    position: relative;
    padding-left: 1.75em;
    font-weight: bold;

    @include breakpoint(lg) {
      font-size: 2rem;
    }

    &::before {
      content: '“';
      position: absolute;
      top: 0;
      left: 0;
      font-size: 2.5em;
      line-height: 1.2;
    }

    cite {
      display: block;
      margin-top: 1em;
      font-size: 0.75em;
      opacity: 0.75;

      &::before {
        content: '\2014';
        margin-right: 0.25em;
      }
    }
  }
}
