/*
** Vendor - Contact form
** -----------------------------------------------------------------------------*/

div.wpcf7 {
  .wpcf7-form-control[name] {
    @extend .form__input;
  }

  .wpcf7-form-control-wrap {
    display: block;
  }

  span.wpcf7-not-valid-tip {
    position: absolute;
    margin-top: 0.15em;
    color: $color-status-error;
    font-size: 1.4rem;
  }

  span.ajax-loader {
    display: none;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    &.is-active {
      display: block;
      margin: 0;
    }
  }

  .wpcf7-submit ~ .u-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  div.wpcf7-response-output {
    margin-left: 0;
    margin-right: 0;
    border-radius: 3px;
    font-size: 1.6rem;
  }

  .wpcf7-validation-errors {
    display: none !important;
  }
}
