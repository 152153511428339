/*
** Utils - Icon
** ----------------------------------------------------------------------------- */

.u-icon {
  display: block;
  width: 100%;
  height: 100%;

  * {
    fill: currentColor;
    stroke: none;
  }

  &--sm {
    width: 0.75em;
    height: 0.75em;
  }

  &--md {
    width: 1em;
    height: 1em;
  }

  &--lg {
    width: 1.25em;
    height: 1.25em;
  }
}
