/*
** Module - Details
** ----------------------------------------------------------------------------- */

.details {
  max-width: 16em;
}

.details__term {
  font-weight: bold;

  strong {
    display: block;
    font-family: $font-oswald;
    font-size: 4.8rem;
    font-weight: 500;
    line-height: 1.1;

    @include breakpoint(md) {
      font-size: 5.6rem;
    }

    @include breakpoint(lg) {
      font-size: 6.4rem;
    }
  }

  &:not(:first-child) {
    margin-top: 2rem;

    @include breakpoint(md) {
      margin-top: 4rem;
    }

    @include breakpoint(lg) {
      margin-top: 6rem;
    }
  }
}

.details__definition {
  font-size: 1.2rem;
  line-height: 1.3;

  &:not(:first-child) {
    margin-top: 0.75em;
  }
}
