/*
** Module - Office
** ----------------------------------------------------------------------------- */

.office__list {
  position: relative;
  z-index: $layer-utils;
  display: flex;
  flex-direction: column;
  padding: 4rem;
  background-color: $color-primary;
  color: $color-white;
  overflow: hidden;

  @include breakpoint(md) {
    padding: 5rem;
  }

  @include breakpoint(lg) {
    height: calc(100% + 14rem);
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
}

.office {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:not(:first-child) {
    margin-top: 4rem;

    @include breakpoint(md) {
      margin-top: 5rem;
    }
  }

  @include breakpoint(lg) {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
}

.office__title {
  position: relative;

  &::after {
    content: '';
    display: block;
    height: 2px;
    width: calc(100% + 4rem);
    margin-left: -4rem;
    margin-top: 0.25em;
    background-color: currentColor;

    @include breakpoint(md) {
      width: calc(100% + 5rem);
      margin-left: -5rem;
    }
  }
}

.office__details {
  p {
    &:not(:first-child) {
      margin-top: 0.33em;
    }
  }

  &:not(:first-child) {
    margin-top: 1.25em;
  }

  a:hover {
    opacity: 0.8;
  }
}
