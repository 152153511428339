/*
** Module - Nav secondary
** ----------------------------------------------------------------------------- */

.nav-secondary {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -1.5em;

  @include breakpoint(lg) {
    margin-left: -2.5em;
  }
}

.nav-secondary__item {
  margin-left: 1.5em;

  @include breakpoint(lg) {
    margin-left: 2.5em;
  }
}

.nav-secondary__link {
  @extend %style-oswald;

  &[href]:hover {
    color: $color-primary;
  }
}
