/*
** Module - Heading
** ----------------------------------------------------------------------------- */

.heading {
  position: relative;
  @extend %heading;

  &--h1 {
    @extend %heading-h1;
  }

  &--h2 {
    @extend %heading-h2;
  }

  &--h3 {
    @extend %heading-h3;
  }
}
