/*
** Module - Lead
** ----------------------------------------------------------------------------- */

.lead {
  display: flex;
  flex-direction: column;
  max-width: 31em;

  &--align-left {
    align-items: flex-start;
    text-align: left;
  }

  &--align-center {
    align-items: center;
    text-align: center;
  }

  &--align-right {
    align-items: flex-end;
    text-align: right;
  }
}

.lead__copy,
.lead__button {
  &:not(:first-child) {
    margin-top: 5rem;
  }
}

.lead__copy {
  font-size: 2rem;

  @include breakpoint(md) {
    font-size: 2.2rem;
  }

  @include breakpoint(lg) {
    font-size: 2.4rem;
  }
}
