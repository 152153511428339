/*
** Module - Hero
** ----------------------------------------------------------------------------- */

.hero {
  @include u-dimmer('top', 20rem, 0.85, $color-white, 'after');
  position: relative;
  background-color: $color-shade;
  color: $color-white;
  max-height: 100vh;

  @include breakpoint(lg) {
    &::before {
      content: '';
      display: block;
      padding-bottom: 56.25%;
    }
  }
}

.hero__image {
  @include u-object-fit;
  @include u-overlay;
  pointer-events: none;
  user-select: none;
}

.hero__content {
  position: relative;
  z-index: $layer-base;
  padding-top: calc(6rem + 3rem + 4rem);
  padding-bottom: 20vw;

  @include breakpoint(md) {
    padding-top: calc(8.4rem + 3rem + 4rem);
  }

  @include breakpoint(lg) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-top: calc(8.4rem + 3rem + 7%);
  }
}

.hero__inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 60rem;
}

.hero__title {
  font-weight: 500;
}

.hero__copy {
  max-width: 26.66em;

  &:not(:first-child) {
    margin-top: 1.25em;
  }
}

.hero__button {
  &:not(:first-child) {
    margin-top: 1.5em;
  }
}
