/*!
** Project: Delta Forces
** Author: Piotr Kulpinski <piotr@kulpinski.pl>
** --------------------------------
**/

@import 'setup/variables';

// Vendor dependencies
@import 'bespoke-toolkit/styles/toolkit';
@import 'mapbox-gl/dist/mapbox-gl';

@import 'setup/mixins';
@import 'setup/animations';
@import 'setup/normalize';

@import "placeholders/_button.scss";
@import "placeholders/_common.scss";
@import "placeholders/_heading.scss";
@import "placeholders/_typography.scss";
@import "common/_base.scss";
@import "common/_fonts.scss";
@import "vendor/_contact-form.scss";
@import "vendor/_flickity.scss";
@import "utils/_hamburger.scss";
@import "utils/_helpers.scss";
@import "utils/_icon.scss";
@import "utils/_label.scss";
@import "utils/_loader.scss";
@import "utils/_logo.scss";
@import "utils/_trademark.scss";
@import "utils/_underline.scss";
@import "modules/_body-bag.scss";
@import "modules/_button.scss";
@import "modules/_copy.scss";
@import "modules/_details.scss";
@import "modules/_form.scss";
@import "modules/_grid.scss";
@import "modules/_heading.scss";
@import "modules/_hero.scss";
@import "modules/_lead.scss";
@import "modules/_map.scss";
@import "modules/_nav-language.scss";
@import "modules/_nav-primary.scss";
@import "modules/_nav-secondary.scss";
@import "modules/_office-card.scss";
@import "modules/_office.scss";
@import "modules/_page-foot.scss";
@import "modules/_page-head.scss";
@import "modules/_panel.scss";
@import "modules/_popup.scss";
@import "modules/_section.scss";
@import "modules/_tile.scss";
