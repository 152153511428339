/*
** Setup - Normalize
** ----------------------------------------------------------------------------- */

* {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
}

:root {
  height: 100%;
  width: 100%;
  font-size: 62.5%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  overflow-x: hidden;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  word-wrap: break-word;
  box-sizing: border-box;
}

*,
::before,
::after {
  box-sizing: inherit;
}

a,
button,
[type='button'] {
  cursor: pointer;
  color: currentColor;
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

strong,
b {
  font-weight: 600;
}

em,
i {
  font-style: italic;
}

img,
svg,
video,
audio,
iframe {
  display: block;
  max-width: 100%;
}

ul,
ol {
  list-style: none;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.4em;
}

fieldset {
  min-width: 0;

  body:not(:-moz-handler-blocked) & {
    display: table-cell;
  }
}

legend {
  display: table;
}
