/*
** Placeholders - Heading
** ----------------------------------------------------------------------------- */

%heading {
  font-family: $font-oswald;
  line-height: 1.4;
}

%heading-h1 {
  font-size: 3.6rem;
  line-height: 1.2;
  text-transform: uppercase;

  @include breakpoint(md) {
    font-size: 4.2rem;
  }

  @include breakpoint(lg) {
    font-size: 4.8rem;
  }
}

%heading-h2 {
  font-size: 2.8rem;
  text-transform: uppercase;

  @include breakpoint(md) {
    font-size: 3.2rem;
  }

  @include breakpoint(lg) {
    font-size: 3.6rem;
  }
}

%heading-h3 {
  font-weight: bold;
  font-size: 2rem;

  @include breakpoint(md) {
    font-size: 2.2rem;
  }

  @include breakpoint(lg) {
    font-size: 2.4rem;
  }
}
