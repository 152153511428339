/*
** Setup - Animations
** ----------------------------------------------------------------------------- */

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(-6rem);
  }
}
