/*
** Utils - Loader
** ----------------------------------------------------------------------------- */

.u-loader {
  position: relative;
  min-width: 100%;
  backface-visibility: hidden;

  &.is-loading {
    min-height: 3em;

    &::after {
      @include u-overlay;
      content: '';
      z-index: $layer-utils;
      background-image: image('loader-dark.svg');
      background-position: center;
      background-repeat: no-repeat;

      .section--theme-black & {
        background-image: image('loader.svg');
      }
    }
  }
}
